<template>
    <div class="flex flex-col space-y-4 p-6">
        <div class="flex space-x-2 items-center">
            <p class="font-poppins font-bold text-base leading-6">
                {{ title }}
            </p>
            <vue-feather v-if="icon" :type="icon" size="24" stroke="orange"></vue-feather>
        </div>
        <p v-if="description" class="text-sm">{{ description }}</p>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: "",
        }
    }
}
</script>