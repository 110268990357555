<template>
  <page-layout title="DashBoard" hideBack logo>
    <Loading v-if="loading" />
    <div v-else>
      <div class="flex flex-col justify-start items-start h-16">
        <p class="heading-1 text-2xl p-5">
          Hey <span class="capitalize text-primary">{{ firstname }}</span>
          <span v-if="user.tutor" class="body font-normal text-base leading-5 text-gray-500">• Tutor</span>
        </p>
      </div>
      <hr />

      <div class="pt-3 flex w-full justify-end px-6">
        <Button @click="this.$router.push('/schedule')" size="small">Update Schedule</Button>
      </div>

      <div class="md:grid md:grid-cols-2 place-content-center">
        <Section title="Session requests" icon="zap" v-if="requests.length > 0">
          <div class="flex flex-col justify-center items-center mt-2">
            <session-info v-for="(_session, i) in requests" :key="i" :session="_session" />
          </div>
        </Section>

        <Section title="Tutoruu Academy" icon="youtube">
          <Card>
            <div class="w-full">
              <h3 class="text-base font-bold py-2">Episode 1 - Starting Out With Tutoruu Academy</h3>
              <p class="text-sm">This lesson is an introduction to our Tutoruu Academy series where you will learn
                essential lessons to
                succeed in your Tutoring Journey.</p>
            </div>
            <a href="https://tutoruu.com/academy/1" target="_blank">
              <Button>Watch</Button>
            </a>
          </Card>
        </Section>

        <Section title="Chats" icon="message-circle" :padding="false">
          <Card>
            <transition-group name="scale-up" initial tag="ul" class="relative w-full"
              v-if="chats && chats.length === user.chats.length">
              <li v-for="chat, index in chats.slice(0, 3)" :key="index">
                <ChatPreview :chat="chat" />
              </li>
            </transition-group>
            <Button @click="this.$router.push('/chats')">All chats</Button>
          </Card>
        </Section>

        <Section title="Upcoming sessions" icon="calendar">
          <Card v-if="upcoming.length > 0">
            <session-info v-for="(_session, i) in upcoming.slice(0, 1)" :key="i" :session="_session" />
            <Button type="ghost" @click="this.$router.push('/sessions')">View all sessions</Button>
          </Card>
          <Card v-else>
            <h3 class="text-base font-bold py-2 w-full">No upcoming sessions</h3>
            <p class="text-sm">Want to get more sessions? Watch Tutoruu academy videos to get the know-how.</p>
            <Button shape="ghost" class="mx-auto" @click="this.$router.push('/sessions')">All sessions</Button>
          </Card>
        </Section>

        <Section title="Quests" description="Earn rewards with quests and become a better tutor." icon="award"
          v-if="quests && quests.length > 0" :key="questKey">
          <Quests v-for="(quest, i) in quests" :key="i" :name="quest.name" :numberOfTimes="quest.number_of_times"
            :currentNumber="quest.quests_progresses.length" />
        </Section>
      </div>
    </div>
  </page-layout>
</template>
  
<script>
import Loading from "../../components/Loading.vue";
import { mapActions, mapState } from "vuex";
import pageLayout from "../../components/base/pageLayout.vue";
import Button from "../../components/Feed/Button.vue";
import SessionInfo from "../../components/SessionInfo.vue";
import Card from "../../components/Feed/Card.vue";
import Section from "../../components/Feed/Section.vue";
import ChatPreview from "../../components/chatPreview.vue";
import Quests from "../../components/Feed/Quests.vue";

export default {
  components: { pageLayout, Loading, SessionInfo, Button, Card, Section, ChatPreview, Quests },
  props: {},
  data() {
    return {
      firstname: "",
      upcoming: [],
      requests: [],
      loading: true,
      questKey: 0,
    };
  },
  computed: {
    ...mapState(['chats', 'user', 'user_tutor', 'profile', 'quests']),
  },

  methods: {
    ...mapActions(['get_chats']),
    sortChats() {
      if (!this.chats) return
      return this.chats.sort((a, b) => (!!a.last_message && !!b.last_message && a.last_message.timestamp < b.last_message.timestamp) ? 1 : ((!!a.last_message && !!b.last_message && b.last_message.timestamp < a.last_message.timestamp) ? -1 : 0));
    },
    setUser() {
      const name = this.user.name.split(" ");
      this.firstname = name[0];
      this.requests = this.user_tutor.unconfirmed_sessions.filter(s => !s.is_completed);
      this.upcoming = this.user.sessions.filter(s => !s.is_completed)
    }
  },
  async ionViewWillEnter() {
    await this.get_chats();
    this.setUser();
    this.loading = false;
  },
  watch: {
    chats: {
      handler: function () {
        this.sortChats()
      },
      deep: true,
      immediate: true,
    },
    quests: {
      handler: function (val) {
        this.questKey++;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
  